import React from 'react';
import { graphql } from 'gatsby';
import { default as SEO } from '../components/SEO';
import {
  SolidDarkTheme,
  NavigationThemeKey,
  ThemeProvider,
  ThemeColorsWrapper,
  TComponentThemeKey,
  Colors
} from '@parsleyhealth/cilantro-ui';
import { Layout } from '../components/Layout';
import {
  PageBuilderModule,
  NonAnimatedComponents
} from './page-builder-template';
import { ErroredModule } from '../components/page-builder';
import { SanityCorePageProps } from '../types/page-builder-types';
import Appear from '../components/animations/appear';
import Banner from '../components/Common/Banner';
import { DigiohScript } from '../components/ThirdParty/digioh-script';

const CorePagesTemplate = ({
  data: { sanityCorePage }
}: SanityCorePageProps) => {
  const themeNav = sanityCorePage.navigationSettings
    ? sanityCorePage.navigationSettings.themeNav
    : SolidDarkTheme;
  const spacer = sanityCorePage.navigationSettings
    ? sanityCorePage.navigationSettings.spacer
    : null;

  return (
    <ThemeProvider>
      <SEO
        pageTitle={sanityCorePage.metaTitle}
        pageDescription={sanityCorePage.metaDescription}
        pageImage={sanityCorePage.metaImage}
        noindex={sanityCorePage.noindex}
      />
      <DigiohScript />
      <Layout themeNav={themeNav as NavigationThemeKey} spacer={spacer}>
        {sanityCorePage._rawBannerPromo && (
          <Banner {...sanityCorePage._rawBannerPromo} />
        )}
        {sanityCorePage.pageBuilderComponents.components.map(props => {
          if (props === null) {
            return (
              <ErroredModule>
                A page builder component was added to the sanity schema but your
                query is missing a request for those component props. Please
                update your page builder query. <br />
                Check your the queries in the component file and
                src/templates/page-builder-template.tsx
              </ErroredModule>
            );
          }
          return (
            <ThemeColorsWrapper
              useSeparatorTop={props?.useSeparatorTop}
              useSeparatorBottom={props?.useSeparatorBottom}
              separatorColor={props?.separatorColor as keyof Colors}
              theme={
                'theme' in props && props.theme
                  ? (props.theme as TComponentThemeKey)
                  : 'whiteBackgroundDarkText'
              }
            >
              {NonAnimatedComponents.includes(props.__typename) ? (
                <PageBuilderModule {...props} />
              ) : (
                <Appear>
                  <PageBuilderModule {...props} />
                </Appear>
              )}
            </ThemeColorsWrapper>
          );
        })}
      </Layout>
    </ThemeProvider>
  );
};

export default CorePagesTemplate;

export const query = graphql`
  query CorePagesTemplateQuery($id: String) {
    sanityCorePage(id: { eq: $id }) {
      slug {
        current
      }
      _rawBannerPromo
      metaTitle
      metaDescription
      metaImage {
        hotspot {
          y
          x
          width
          height
        }
        crop {
          bottom
          left
          right
          top
        }
        asset {
          url
        }
      }
      navigationSettings {
        themeNav
        spacer
      }
      noindex
      pageBuilderComponents {
        components {
          __typename
          ... on SanityCenteredText {
            ...SanityCenteredTextFragment
          }
          ... on SanityColumnImageBlocks {
            ...SanityColumnImageBlocksFragment
          }
          ... on SanityFaqBlockPageBuilder {
            ...SanityFaqBlockPageBuilderFragment
          }
          ... on SanityHeroNew {
            ...SanityHeroNewFragment
          }
          ... on SanityInfographics {
            ...SanityInfographicsFragment
          }
          ... on SanityNonContentBlock {
            ...SanityNoContentBlockFragment
          }
          ... on SanityPlanSnapshotsArray {
            ...SanityPlanSnapshotsArrayFragment
          }
          ... on SanityRenewal {
            ...SanityRenewalFragment
          }
          ... on SanitySimpleReviewsComponent {
            ...SanitySimpleReviewFragment
          }
          ... on SanityTestimonialsComponent {
            ...SanityTestimonialFragment
          }
          ... on SanityTextAndCta {
            ...SanityTextAndCtaFragment
          }
          ... on SanityTextAndImage {
            ...SanityTextAndImageFragment
          }
          ... on SanityTextBlockSection {
            ...SanityTextBlockSectionFragment
          }
          ... on SanityTextImageAndIcons {
            ...SanityTextImageAndIconsFragment
          }
          ... on SanityTextAndIcons {
            ...SanityTextAndIconsFragment
          }
          ... on SanityTextAndVideoTwoColumn {
            ...SanityTextAndVideoTwoColumnFragment
          }
          ... on SanityThreeColumnIcon {
            ...SanityThreeColumnIconFragment
          }
          ... on SanityVideoCarousel {
            ...SanityVideoCarouselFragment
          }
        }
      }
    }
  }
`;
